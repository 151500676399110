app-page.transition-stack-lower-out > .content {
  animation-name: transition-stack-lower-scale;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}
app-page.transition-stack-lower-out > .app-bar {
  position: relative;
}
app-page.transition-stack-lower-out > .app-bar::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: var(--mdc-theme-primary, #000);
  content: "";
  animation-name: transition-stack-fade;
  animation-duration: 90ms;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

app-page.transition-stack-upper-in {
  animation-name: transition-stack-fade;
  animation-duration: 210ms;
  animation-timing-function: ease-in;
  animation-delay: 90ms;
  animation-fill-mode: both;
}
app-page.transition-stack-upper-in > .content {
  animation-name: transition-stack-upper-scale;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

app-page.transition-stack-lower-in > .content {
  animation-name: transition-stack-lower-scale;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
}
app-page.transition-stack-lower-in > .app-bar {
  position: relative;
}
app-page.transition-stack-lower-in > .app-bar::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: var(--mdc-theme-primary, #000);
  content: "";
  animation-name: transition-stack-fade;
  animation-duration: 210ms;
  animation-timing-function: ease-in;
  animation-delay: 90ms;
  animation-direction: reverse;
  animation-fill-mode: both;
}

app-page.transition-stack-upper-out {
  animation-name: transition-stack-fade;
  animation-duration: 90ms;
  animation-timing-function: ease-out;
  animation-direction: reverse;
  animation-fill-mode: both;
  display: var(--app-page-display, flex);
}
app-page.transition-stack-upper-out > .content {
  animation-name: transition-stack-upper-scale;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
}

.navigation mwc-icon:not([color]), .navigation mwc-icon[color=font-on-white] {
  filter: brightness(0);
  opacity: 0.87;
}
.navigation mwc-icon:not([color])::after, .navigation mwc-icon[color=font-on-white]::after {
  display: none;
}