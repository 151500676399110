.navigation {
    mwc-icon {
        &:not([color]),
        &[color='font-on-white'] {
            filter: brightness(0);
            opacity: .87;

            &::after {
                display: none;
            }
        }
    }
}
