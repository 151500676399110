@use 'variables';

@mixin host($selector, $host) {
    @if $host == 'host' {
        :host(#{$selector}) {
            @content;
        }
    }@else {
        #{$host}#{$selector} {
            @content;
        }
    }
}

@mixin slotted($selector, $host) {
    @if $host == 'host' {
        ::slotted(#{$selector}) {
            @content;
        }
    } @else {
        > #{$selector} {
            @content;
        }
    }
}

// navigation stack animations
@mixin stack-fade-in() {
    animation-name: transition-stack-fade;
    animation-duration: 210ms;
    animation-timing-function: ease-in;
    animation-delay: 90ms;
    animation-fill-mode: both;
}

@mixin stack-fade-out() {
    animation-name: transition-stack-fade;
    animation-duration: 90ms;
    animation-timing-function: ease-out;
    animation-direction: reverse;
    animation-fill-mode: both;
}

@mixin app-bar-fade-out() {
    animation-name: transition-stack-fade;
    animation-duration: 90ms;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
}

@mixin app-bar-fade-in() {
    animation-name: transition-stack-fade;
    animation-duration: 210ms;
    animation-timing-function: ease-in;
    animation-delay: 90ms;
    animation-direction: reverse;
    animation-fill-mode: both;
}

@mixin app-bar-overlay($host) {
    @include slotted('.app-bar', $host) {
        position: relative;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 4;
            background-color: var(--mdc-theme-primary, #000);
            content: '';

            @content;
        }
    }
}

@mixin page-animations($host) {
    // push page to stack
    @include host('.transition-stack-lower-out', $host) {
        @include slotted('.content', $host) {
            animation-name: transition-stack-lower-scale;
            animation-duration: 300ms;
            animation-timing-function: ease-in-out;
        }

        @include app-bar-overlay($host) {
            @include app-bar-fade-out;
        }
    }


    @include host('.transition-stack-upper-in', $host) {
        @include stack-fade-in;

        @include slotted('.content', $host) {
            animation-name: transition-stack-upper-scale;
            animation-duration: 300ms;
            animation-timing-function: ease-in-out;
        }
    }

    // pop page from stack
    @include host('.transition-stack-lower-in', $host) {
        @include slotted('.content', $host) {
            animation-name: transition-stack-lower-scale;
            animation-duration: 300ms;
            animation-timing-function: ease-in-out;
            animation-direction: reverse;
        }

        @include app-bar-overlay($host) {
            @include app-bar-fade-in;
        }
    }


    @include host('.transition-stack-upper-out', $host) {
        @include stack-fade-out;

        display: variables.$display;

        @include slotted('.content', $host) {
            animation-name: transition-stack-upper-scale;
            animation-duration: 300ms;
            animation-timing-function: ease-in-out;
            animation-direction: reverse;
        }
    }
}
